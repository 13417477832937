import { Vue } from 'vue-class-component'
import SysOrder, { SysOrderStorage } from '@/types/SysOrder'
import SysCart from '@/types/SysCart'
import { Orders } from '@/services/OrdersDataService'
import { Competitions } from '@/services/CompetitionsDataService'
import MembersDataService from '@/services/MembersDataService'
import { History } from '@/services/HistoryDataService'
import ClubsDataService from '@/services/ClubsDataService'
import { Events } from '@/services/EventsDataService'
import CommonFunctions from '@/components/Utility/Common'
import router from '@/router'
import TeamsDataService from '@/services/TeamsDataService'
import RegistrationFeeDataService from '@/services/RegistrationFeeDataService'

export default class checkoutSuccess extends Vue {
  finished = false
  private clubpurchaseonlyinfoData: { clubPurchaseId: number, clubPurchaseType: number, clubPurchaseUUID: string }[] = [{ clubPurchaseId: 0, clubPurchaseType: 0, clubPurchaseUUID: '' }]

  public async finishTransaction () : Promise<void> {
    const orderString = localStorage.getItem('order')
    const cartString = localStorage.getItem('cart')
    const clubpurchaseonlyinfoString = localStorage.getItem('clubpurchaseonlyinfo')

    if (orderString === null) {
      return
    }
    const orderObj = JSON.parse(orderString) as SysOrderStorage
    const timeInSec = (Date.now() / 1000)

    if (cartString === null || (timeInSec - orderObj.timeStamp) > 3600) {
      // If the cart is empty, something have gone wrong
      // The same is the case, if more than hour have elapsed since communication with Bamboa
      // Updates the order_status to "annulleret" ,removes it from localStorage and return
      const updateOrder = {
        ordre_status: 'annulleret'
      }

      try {
        Orders.OrdersDataService.update(orderObj.toString(), updateOrder)
      } catch (err) {
        console.log(err)
      }

      localStorage.removeItem('order')

      return
    }

    const cartArray = JSON.parse(cartString) as SysCart[]

    if (orderObj.clubPurchase) {
      // If this situation is a club purchase, then there is nothing more to do, but to remove order and cart data from local storage.
      if (clubpurchaseonlyinfoString !== null) {
        // In case it was a club purchase only, then parse some data and update the info object accordingly.
        const clubpurchaseonlyinfoObj = JSON.parse(clubpurchaseonlyinfoString) as { klubkoeb_id: number, klubkoeb_type: number, klubkoeb_uuid: string }[]
        this.clubpurchaseonlyinfoData = []
        if (clubpurchaseonlyinfoObj !== null && clubpurchaseonlyinfoObj.length >= 1) {
          for (const infoObjItem of clubpurchaseonlyinfoObj) {
            this.clubpurchaseonlyinfoData.push({ clubPurchaseId: (infoObjItem.klubkoeb_id !== undefined && infoObjItem.klubkoeb_id !== null ? Number(infoObjItem.klubkoeb_id) : 0), clubPurchaseType: (infoObjItem.klubkoeb_type !== undefined && infoObjItem.klubkoeb_type !== null ? Number(infoObjItem.klubkoeb_type) : 0), clubPurchaseUUID: (infoObjItem.klubkoeb_uuid !== undefined && infoObjItem.klubkoeb_uuid !== null ? infoObjItem.klubkoeb_uuid : '') })
          }
        }
      }

      localStorage.removeItem('order')
      localStorage.removeItem('cart')
      // localStorage.removeItem('clubpurchaseonlyinfo')
      this.finished = true
    } else {
      // In case it is not a club purchase, execute this.
      localStorage.removeItem('order')
      localStorage.removeItem('cart')
      // localStorage.removeItem('clubpurchaseonlyinfo')
      this.finished = true
    }
  }

  async mounted () : Promise<void> {
    this.finishTransaction()
  }
}
